import PropTypes from "prop-types";
import React from 'react';
import { Link } from "react-router-dom";


const Logo = ({ image }) => {
    return (
        <div>
            <Link to={process.env.PUBLIC_URL + "/"} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: "center" }}>
                <img height="60px" className="dark-logo" src={process.env.PUBLIC_URL + image} alt="Agency Logo" />
                <div style={{display:'flex', alignItems:'center', flexDirection:'column'}}>
                    <p style={{ color: 'white', fontSize: '30px', fontWeight: 'bold', marginBottom: '0px', letterSpacing:'4px' }}>TECHDEETS</p>
                    <p style={{ color: 'white', fontSize: '12 px', fontWeight: 'bold', marginTop: '0px', letterSpacing:'0px' }}>INFO SOLUTIONS PVT. LTD</p>
                </div>
            </Link>
        </div>
    )
}

Logo.propTypes = {
    image: PropTypes.string
};

export default Logo;
